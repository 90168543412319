import React, { useEffect, useState } from 'react';
import './imgGallery.css'; // Import the CSS file
import Grid from "@mui/material/Grid";
import { Container, keyframes } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";
import MKButton from 'components/MKButton';
import MKAvatar from 'components/MKAvatar';
import CountUp from "react-countup";
import ProfileImage from '../../../assets/images/project/test/models/1/images/profile_image/SGT_1626277271.png';
import { capitalizeFirstLetter } from 'shared libraries/tools';
import { Helmet } from 'react-helmet';

const ProfileDetails = ({actorData}) => {

  return (
    <MKBox component="section" py={3} >
        <Container>
        { actorData &&(
            <Grid container spacing={3} className="">
                <Helmet>
                    <title>Casting Beans | {actorData.name} | Actors | Model | Female Model | Casting Beans | Talents Management</title>
                </Helmet>
                <Grid item xs={12}  mt={5} justifyContent="left" display="flex">
                    <MKBox >
                        <MKAvatar 
                            src={process.env.REACT_APP_IMG_URL + decodeURIComponent(actorData.profile_image)} 
                            alt={actorData.name} 
                            size="xxl" 
                            sx={{ 
                                width: {lg: '150px', md: '120px', xs: '100px'}, 
                                height: {lg: '150px', md: '120px', xs: '100px'},
                                boxShadow: '0 0 10px 10px rgba(0, 0, 0, .2)'
                            }} />
                    </MKBox>
                    <MKBox pt={{ lg:4, xs:2 }} pl={{ lg:4, xs:3 }} pb={{ lg:4, xs:0 }}>
                        <MKTypography 
                            variant="h3"  
                            sx={{
                                fontSize: {
                                xs: '1.25rem',  // Adjust font size for extra-small screens (xs)
                                md: '1.5rem',   // Adjust font size for medium screens (md)
                                lg: '2rem'      // Adjust font size for large screens (lg)
                                },
                                fontWeight: 'bold', // Optional: Adjust font weight as needed
                            }}
                        >
                            { capitalizeFirstLetter(actorData.name) }
                        </MKTypography>
                        <Grid container spacing={1} mb={3} display={{ md:'flex', xs:'none'}}>
                            <Grid item id="target-id">
                                {actorData.years && actorData.years > 0 ?
                                    (
                                        <MKTypography component="span" variant="h6"  fontWeight="bold">
                                            <CountUp end={actorData.years} duration={5} />&nbsp;
                                        </MKTypography>
                                    ):(
                                        <MKTypography component="span" variant="h6"  fontWeight="bold">
                                            NA &nbsp;
                                        </MKTypography>
                                    )
                                }
                                <MKTypography component="span" variant="h6" color="secondary">
                                    Age
                                </MKTypography>
                            </Grid>
                            <Grid item >
                                {actorData.height && actorData.height > 0 ?
                                    (
                                        <MKTypography component="span" variant="h6"  fontWeight="bold">
                                            <CountUp end={actorData.height} duration={4} decimal="." decimals={1} />&nbsp;
                                        </MKTypography>
                                    ):(
                                        <MKTypography component="span" variant="h6"  fontWeight="bold">
                                            NA &nbsp;
                                        </MKTypography>
                                    )
                                }
                                <MKTypography component="span" variant="h6" color="secondary">
                                    Height
                                </MKTypography>
                            </Grid>
                            <Grid item >
                            {actorData.vitals && (typeof actorData.vitals[0] === 'number' && typeof actorData.vitals[1] === 'number' && typeof actorData.vitals[2] === 'number') ?
                                (
                                    <MKTypography component="span" variant="h6"  fontWeight="bold">
                                        {actorData.vitals[0] && actorData.vitals[0] > 0 &&(
                                            <>
                                            <CountUp end={actorData.vitals[0]} duration={2} />-
                                            </>
                                        )}
                                        {actorData.vitals[1] && actorData.vitals[1] > 0 &&(
                                            <>
                                            <CountUp end={actorData.vitals[1]} duration={4} />-
                                            </>
                                        )}
                                        {actorData.vitals[2] && actorData.vitals[2] > 0 &&(
                                            <>
                                            <CountUp end={actorData.vitals[2]} duration={6} />&nbsp;
                                            </>
                                        )}
                                    </MKTypography>
                                ):(
                                    <MKTypography component="span" variant="h6"  fontWeight="bold">
                                        NA &nbsp;
                                    </MKTypography>
                                )
                            }
                                <MKTypography component="span" variant="h6" color="secondary">
                                    Vitals
                                </MKTypography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} mb={3} display={{ md:'none', xs:'flex'}}>
                            <Grid item >
                                {actorData.years && actorData.years > 0 ?
                                    (
                                        <MKTypography component="span" variant="overline"  fontWeight="bold">
                                            <CountUp end={actorData.years} duration={5} />&nbsp;
                                        </MKTypography>
                                    ):(
                                        <MKTypography component="span" variant="overline"  fontWeight="bold">
                                            NA&nbsp;
                                        </MKTypography>
                                    )
                                }

                                <MKTypography component="span" variant="overline" color="secondary">
                                    Age
                                </MKTypography>
                            </Grid>
                            <Grid item >
                                {actorData.height && actorData.height > 0 ?
                                    (
                                        <MKTypography component="span" variant="overline"  fontWeight="bold">
                                            <CountUp end={actorData.height} duration={4} decimal="." decimals={1} />&nbsp;
                                        </MKTypography>
                                    ):(
                                        <MKTypography component="span" variant="overline"  fontWeight="bold">
                                            NA&nbsp;
                                        </MKTypography>
                                    )
                                }
                                <MKTypography component="span" variant="overline" color="secondary">
                                    Height
                                </MKTypography>
                            </Grid>
                            <Grid item >
                            {actorData.vitals && (typeof actorData.vitals[0] === 'number' && typeof actorData.vitals[1] === 'number' && typeof actorData.vitals[2] === 'number') ?
                                (
                                    <MKTypography component="span" variant="overline"  fontWeight="bold">
                                    {actorData.vitals[0] && actorData.vitals[0] > 0 &&(
                                            <>
                                            <CountUp end={actorData.vitals[0]} duration={2} />-
                                            </>
                                        )}
                                        {actorData.vitals[1] && actorData.vitals[1] > 0 &&(
                                            <>
                                            <CountUp end={actorData.vitals[1]} duration={4} />-
                                            </>
                                        )}
                                        {actorData.vitals[2] && actorData.vitals[2] > 0 &&(
                                            <>
                                            <CountUp end={actorData.vitals[2]} duration={6} />&nbsp;
                                            </>
                                        )}
                                    </MKTypography>
                                ):(
                                    <MKTypography component="span" variant="overline"  fontWeight="bold">
                                        NA&nbsp;
                                    </MKTypography>
                                )
                            
                            }
                                <MKTypography component="span" variant="overline" color="secondary">
                                    Vitals
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
                {actorData.biography &&(
                <Grid item xs={12}  justifyContent="left">
                    <MKTypography variant="body1"  fontWeight="light" color="text">
                        {actorData.biography}     
                    </MKTypography>
                </Grid>
                )}
            </Grid>
        )}    
      </Container>
    </MKBox>
  );
};

export default ProfileDetails;
